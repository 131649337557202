import styled from "styled-components";
import { TextField } from "@mui/material";
import { Link } from "react-router-dom";
import { Menu, Search } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";


export const MenuIcon = styled(Menu)`
  color: ${({ color }) => color};
`;

export const StickyNavbar = styled.div`
  position: relative;
  /* width: 100%; */
  background: #D0BCA0;
  padding: .4rem;
`

export const NavbarContainer = styled.div`
  /* background: #fff; */
  /* background: rgb(255, 128, 1);
  background: linear-gradient(
    0deg,
    rgba(255, 128, 1, 1) 35%,
    rgba(255, 215, 95, 1) 100%
  ); */
  display: flex;
  align-items: center;
  /* padding: 0.5rem 0; */
  /* box-shadow: 1px 8px 5px -3px rgba(240,240,240,1); */
  position: relative;
  width: 100%;
  z-index: 999;

  @media screen and (min-width: 992px) {
    /* padding: 1rem 0; */
  }

  .MuiPaper-root {
    background-color: #fff;
    /* box-shadow: 0 0 5px 0 rgb(0 0 0 / 9%) !important; */
  }
`;

export const NavbarList = styled.div`
  display: block;
  width: 100%;
`;

export const NavbarUpper = styled.div`
  position: relative;
  top: 0;
  right: 0;
  display: none;
  padding: 1rem 0;

  @media screen and (min-width: 768px){
    display: flex;
    justify-content: space-between
  }
`;

export const NavbarLower = styled.div`
  justify-content: center;
  display: flex;
  flex-grow: 1;
  padding: 2rem 0;
`;

export const NavbarLowerList = styled.div`
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0;
  list-style: none;
  align-items: center;
  display: flex;
  justify-content: space-around;
  width: 100%;
 
`;

export const NavbarLink = styled(Link)`
  color: ${({ color }) => color};
  font-weight: ${({ bold }) => bold};
  align-items: center;
  display: flex;
  padding: 0 1rem;
  // position: relative;
  text-decoration: none;

  @media screen and (min-width: 992px) {
    padding: 0 2rem;
  }

  @media screen and (min-width: 1440px) {
    padding: 0 2rem;
  }

  &:last-child {
    border-right: unset;
  }
`;

export const NavbarLogo = styled.a`
  width: 220px;
  /* margin-right: 1rem; */
  padding: 0 2rem;

  @media screen and (min-width: 992px) {
    width: 180px;
  }

  @media screen and (min-width: 1024px) {
    width: 180px;
  }

  @media screen and (min-width: 1440px) {
    width: 140px;
  }
  /* padding-top: 8px; */
  img {
    width: 220px;

    @media screen and (min-width: 992px) {
      width: 140px;
    }

    @media screen and (min-width: 1440px) {
      width: 140px;
    }
  }
`;

export const NavbarIconList = styled.div`
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0;
  list-style: none;
  align-items: center;
  display: flex;
`;

export const NavbarIconLink = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
`;

export const ShapeCircle = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #eeeeee;
  color: #000;
  white-space: nowrap;
  vertical-align: baseline;
  padding: 0.6rem 0.8rem;
  align-items: center;
  justify-content: center;
  display: flex;
  box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 6%);
  margin: ${({ margin }) => margin};
  cursor: pointer;

  @media screen and (min-width: 992px) {
    width: 40px;
    height: 40px;
    padding: 0.3rem 0.5rem;
  }

  /* @media screen and (min-width: 1024px) {
    width: 50px;
    height: 50px;
    padding: 0.3rem 0.5rem;
  } */

  @media screen and (min-width: 1440px) {
    padding: 0.6rem 0.8rem;
  }
`;

export const Icon = styled.img`
  width: 80%;
  margin: 0 auto;

  @media screen and (min-width: 992px) {
    width: 70%;
  }
`;

export const Badge = styled.span`
  border-radius: 50%;
  background: #5abb4a;
  color: #fff;
  font-size: 1em;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  padding: 0.2rem 0.4rem;
  display: initial;
  font-weight: 600;
  top: -20%;
  margin-left: -20px;
  position: absolute;
  right: -8px;
`;

export const NavbarMobileLogo = styled.div`
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;

  @media screen and (min-width: 576px) {
    margin-left: 8rem;
  }

  @media screen and (min-width: 768px) {
    margin-left: 1rem;
  }
  img {
    width: 200px;
    margin: 0 0.2rem;
    /* @media screen and (min-width: 768px) {
      width: 200px;
      margin: unset;
    } */
    @media screen and (min-width: 992px) {
      width: 190px;
    }
  }
`;

export const NavbarIconMobileList = styled.div`
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0;
  list-style: none;
  align-items: center;
  display: flex;

  .MuiListItem-root {
    display: flex;
    @media screen and (min-width: 992px) {
      display: block;
    }
  }
`;

export const NavLinking = styled.div`
  position: relative;
  width: 100%;
  color: #1e1e1e;
  text-align: center;
  display: block;
  padding: 0.4rem 0;

  .MuiMenuItem-root {
    display: block;
  }
  .MuiListItem-button:hover {
    background-color: #7f222c !important;
  }
`;

export const NavbarLeftList = styled.div`
  align-items: center;
  display: flex;
`;

export const SearchContainerDesktop = styled.div`
  align-items: center;
  display: flex;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 50%;
`;

export const Box = styled.div`
  padding: 0.66rem 1rem;
  border-top: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background: #f8f8f8;
  p {
    color: #000;
    margin: 0;
  }
`;

export const SearchField = styled(TextField)`
  display: block !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #eeeeee;
  background-clip: padding-box;
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;

export const SearchButton = styled(Link)`
  /* background: #7f222c; */
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  padding: 0.6rem 0.8rem;
  /* border-top-right-radius: 20px;
  border-bottom-right-radius: 20px; */
  align-items: center;
  display: flex;
`;

export const SearchIcon = styled(Search)`
  color: ${({ color }) => color};
`;

const config = {

  theContact: {
    position: 'absolute',
    top: '50%',
    transfrom: 'translateY(-50%)'
  },

  theLeftSide: {
    display: 'flex',
    alignItems: 'center'
  },

  theFlex: {
    alignItems: 'center',
    display: 'flex',
    borderTopLeftRadius: '20px',
    borderBottomLeftRadius: '20px',
    borderTopRightRadius: '20px',
    borderBottomRightRadius: '20px',
  },

  theBox: {
    padding: '9.1px 1rem',
    borderTop: '1px solid #eeeeee',
    borderLeft: '1px solid #eeeeee',
    borderBottom: '1px solid #eeeeee',
    borderTopLeftRadius: '20px',
    borderBottomLeftRadius: '20px',
    background: '#f8f8f8',
  },

  theFormControlSearch: {
    display: 'block',
    padding: '0.375rem 0.75rem',
    fontSize: '1rem',
    lineHeight: 1.5,
    color: '#495057',
    backgroundColor: ' #eeeeee',
    backgroundClip: 'padding-box',

    "& $notchedOutline": {
      borderWidth: 0
    },
    "&:hover $notchedOutline": {
      borderWidth: 0,
      // border: '1px solid #eeeeee'
    },
    "&$focused $notchedOutline": {
      borderWidth: 0
    }
  },

  theSearchBtn: {
    background: '#7f222c',
    color: '#fff',
    padding: '8.6px .6rem',
    borderTopRightRadius: '20px',
    borderBottomRightRadius: '20px',
    alignItems: 'center',
    display: 'flex',
  },

  theLogo: {
    "& img": {
      width: '200px',
      marginLeft: '-10rem'
    }
  },

  theNavList: {
    paddingLeft: 0,
    marginBottom: 0,
    marginTop: 0,
    listStyle: 'none',
    alignItems: 'center',
    display: 'flex',
  },
  theStickLink: {
    marginRight: '1rem',
    textDecoration: 'none',
    color: '#000',
    fontSize: 12,
    cursor: "pointer",
  },

  theIcon_: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },

  theCircle: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    background: '#eeeeee',
    color: '#000',
    whiteSpace: 'nowrap',
    verticalAlign: 'baseline',
    padding: '0.6rem 0.8rem',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.06)',

    "& img": {
      width: '70%',
      margin: '0 auto',
    }
  },

  theNavLinking: {
    position: 'relative',
    width: '100%',
    color: '#1e1e1e',
    textAlign: 'center',
    display: 'block',
    padding: '0.4rem 0',
    textDecoration: 'none',
  },

  theNavLinkingLogout: {
    position: 'relative',
    width: '100%',
    color: '#1e1e1e',
    textAlign: 'center',
    display: 'unset',
    padding: '0.4rem 0',
    textDecoration: 'none',
  },

  theFlexSearch: {
    display: 'none',
    borderTopLeftRadius: '20px',
    borderBottomLeftRadius: '20px',
    borderTopRightRadius: '20px',
    borderBottomRightRadius: '20px',
    width: '100%',
  },

  theNavListMobile: {
    paddingLeft: 0,
    marginBottom: 0,
    marginTop: 0,
    listStyle: 'none',
    alignItems: 'center',
    display: 'flex',
  },

  theBadge: {
    borderRadius: '50%',
    background: '#5abb4a',
    color: '#fff',
    fontSize: '1em',
    lineHeight: 1,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'baseline',
    padding: '0.2rem 0.4rem',
    display: 'initial',
    fontWeight: 600,
    top: '-12px',
    marginLeft: '-20px',
    position: "absolute",
    right: '5px',
  },


  theNavBar: {
    backgroundColor: "#fff",
    position: "relative",
  },

  theNBTop: {
    display: 'flex',
    alignItems: "center",
    justifyContent: 'space-between',

    ...md("md", {
      justifyContent: "center",
    }),
  },
  theLogo: {
    position: "relative",
    width: "200px",
    height: "100%",

    ...md("sm", {
      width: "323px",
      height: "97px",
    }),

    "& img": {
      width: "100%",
      // height: "30",
    },
  },
  theNBDivider: {
    width: "100%",
    display: "none",

    ...md("md", {
      display: "block",
    }),
  },
  theNBBottom: {
    alignItems: "center",
    justifyContent: "center",
    padding: "24px 0",
    display: "none",

    ...md("md", {
      display: 'flex',
    }),

  },
  theNBMenu: {
    alignItems: "center",
    justifyContent: "center",
    padding: "24px 0",
    display: 'flex',

    ...md("md", {
      display: "none",
    }),

  },
  theNBTopMob: {
    display: 'flex',
    alignItems: "center",
    justifyContent: 'space-between',
    padding: '6px 0'
  },
  theNBDividerMob: {
    borderTop: "1px solid #7C7C7C",
    width: "100%",
    display: "block",
  },
  theNBBottomMob: {
    alignItems: "center",
    justifyContent: "center",
    padding: "24px 0",
    display: "flex",
    flexDirection: "column",

  },
  theNBBIcon: {
    // border: "1px solid #7C7C7C",
    // borderRadius: "10px",
    // display: 'flex',
    // alignItems: "center",
    // justifyContent: "center",
    // padding: '5px'
  },
  theLink: {
    textDecoration: "none",
    cursor: "pointer"
  },
  theShopNav: {
    display: "none",
    ...md("sm", {
    }),
    ...md("md", {
      display: "block",
      position: "absolute",
      right: "0",
      top: "99%",
      background: "#FFFFFF",
      width: "100%",
      transition: 'visibility 0s, opacity 0.5s linear',
      visibility: "visible",
      opacity: "1",
      height: "450px",
      boxShadow: "rgba(0, 0, 0, 0.45) 0px 20px 20px -20px",
    }),
  },
  theShopNavHide: {
    display: "none",
    ...md("sm", {
    }),
    ...md("md", {
      display: "block",
      position: "absolute",
      right: "0",
      top: "99%",
      background: "#FFFFFF",
      width: "100%",
      visibility: "hidden",
      opacity: "0",
      height: "450px",
      boxShadow: "rgba(0, 0, 0, 0.45) 0px 20px 20px -20px",
    }),
  },
  theSNContent: {
    margin: 'auto',
    width: "80%",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
    // border: "1px solid #000",
    padding: "2% 4%",
  },
  theSNCLeft: {
    width: "20%",
    borderRight: "0.5px solid #707070",
    padding: "0 2%",
  },
  theSNCFlexStartBetween: {
    display: 'block',
    justifyContent: 'space-between',

    ...md("sm", {
      display: 'flex',
    }),
  },
  theSNCFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: "0 0 1.5rem",
    cursor: "pointer",
  },
  theSNCRight: {
    width: "70%",
    padding: "0 2%"
  },
  theShopNavMob: {
    display: "block",
    background: "#FFFFFF",
    width: "100%",
    transition: 'visibility 0s, opacity 0.5s linear',
    visibility: "visible",
    opacity: "1",
    height: "450px",

    ...md("md", {
      display: 'none'
    }),
  },
  theShopNavMobHide: {
    display: "block",
    width: "100%",
    background: "#FFFFFF",
    visibility: "hidden",
    opacity: "0",
    height: "450px",

    ...md("md", {
      display: 'none'
    }),
  },
  theSNContentMob: {
    margin: 'auto',
    // width: "100%",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
    // border: "1px solid #000",
    padding: "2% 0",
  },
  theSNCLeftMob: {
    // width: "100%",
    borderBottom: "0.5px solid #707070",
    borderRight: "none",
    padding: "0 4%",

    ...md("sm", {
      width: "20%",
      borderRight: "0.5px solid #707070",
      borderBottom: "none",
      padding: "0 2%",
    }),
  },
  theSNCRightMob: {
    // width: "100%",
    padding: "4% 4%",
    overflowX: 'scroll',
    height: "280px",

    ...md("sm", {
      height: "320px",
    }),

    ...md("sm", {
      width: "70%",
      padding: "0 2%",
    }),
  },

  theMobileLogo: {
    "& img": {
      width: '100%',
    }
  }
};


export default makeStyles(config);

import { useCallback, useState, useEffect } from "react"

export function useLocalStorage(key, defaultValue) {
  return useLang(key, defaultValue, window.localStorage)
}

export function useSessionStorage(key, defaultValue) {
  return useLang(key, defaultValue, window.sessionStorage)
}

function useLang(key, defaultValue = "1", storageObject) {
  // console.log('defaultValue', defaultValue)
  if (defaultValue == '1' || defaultValue == null) {
    window.localStorage.setItem("language", '1');
    window.localStorage.setItem("langJson", JSON.stringify(require('../language/cn.json')))
  } else if (defaultValue == '2') {
    window.localStorage.setItem("langJson", JSON.stringify(require('../language/zh.json')))
  } else if (defaultValue == '3') {
    window.localStorage.setItem("langJson", JSON.stringify(require('../language/en.json')))
  }
  const [value, setValue] = useState(() => {
    const jsonValue = storageObject.getItem(key)
    if (jsonValue != null) return JSON.parse(jsonValue)

    // if (typeof defaultValue === "function") {
    //   return defaultValue()
    // } else {
    //   return defaultValue
    // }
  })

  useEffect(() => {
    if (value === undefined) return storageObject.removeItem(key)
    storageObject.setItem(key, JSON.stringify(value))
  }, [key, value, storageObject])

  const remove = useCallback(() => {
    setValue(undefined)
  }, [])

  return [value, setValue, remove]
}
import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import "./App.css";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import UserContextProvider from "./contexts/UserContext";
import CartContextProvider from "./contexts/CartContext";
import EN from './lang/en.json';
import CH from './lang/ch.json';
import BM from './lang/bm.json';
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          ...EN
        }
      },
      ch: {
        translation: {
          ...CH
        }
      },
      bm: {
        translation: {
          ...BM
        }
      }
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

function App() {
  return (
    <CartContextProvider>
      <UserContextProvider>
        <BrowserRouter>
          <Routes></Routes>
        </BrowserRouter>
      </UserContextProvider>
    </CartContextProvider>
  );
}

export default App;

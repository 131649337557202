import React from "react";
import { Container, Grid } from "@mui/material";
import useStyles from "./styles";
import loginmobilelogo from "../../assets/images/loginmobilelogo.png"
import Paragraph from "../Typography/Paragraph";
import footerplane from "../../assets/images/footerplane.svg"
import FormInput from "../../components/FormInput/FormInput";
import footercall from "../../assets/images/footercall.svg"
import footerlocation from "../../assets/images/footerlocation.svg"
import footermail from "../../assets/images/footermail.svg"
import logo from "../../assets/images/logo.png"
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import BusinessIcon from '@mui/icons-material/Business';
import logotwo from "../../assets/images/logotwo.jpg";

import EmailIcon from '@mui/icons-material/Email';
const Footer = () => {
  const classes = useStyles();
  const isMobile = window.innerWidth <= 768;


  return (
    <React.Fragment>
      <div className={classes.theFooterBanner}>
        <Container>

          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={4} >
              <div className={classes.thePaddingOne}>
                <div className={classes.theFooterLogo}>
                  <img src={logo} />
                </div>

                {/* <Paragraph color="#000" size="14px" bold="400" margin="0.75rem 0" style={{ lineHeight: "20px" }}>
                  Financing businesses since 2023
                </Paragraph> */}

                {/* <div className={classes.theAboutBtn}>
                  <Paragraph center color="#FFFFFF" size="14px" bold="500" >
                    About Us
                  </Paragraph>
                </div> */}
              </div>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={4} >
              <div className={classes.thePaddingTwo}>
                <Paragraph color="#fff" size="18px" bold="500" >
                  Our Partners
                </Paragraph>
                <br />
                <div className={classes.theFooterLogoPartner}>
                  <img src={logotwo} />
                </div>
                <br />
                
              </div>
            </Grid> */}
            {/* <Grid item xs={12} sm={12} md={4} >
              <div className={classes.thePaddingTwo}>
                <Paragraph color="#FFFFFF" size="18px" bold="500" >
                  Address
                </Paragraph>
                <br />

                <Paragraph color="#ffffff" size="14px" bold="500" >
                  27, 1st floor
                  Jalan Tanjung 3
                  81800 Ulu Tiram, Johore
                  Malaysia
                </Paragraph>

              </div>
            </Grid> */}
            <Grid item xs={12} sm={12} md={4} >
              <div className={classes.thePaddingTwo}>
                <Paragraph color="#2d2d2b" size="18px" bold="500" >
                  Vison
                </Paragraph>
                <br />
                <div>
                  <Paragraph color="#2d2d2b" size="14px" bold="500" margin="0 0 0 0.5rem">
                    CK Group envision a future where technology seamlessly integrates with strategic marketing initiatives to evolving needs of business. In this era of digital transformation, we are committed to propelling our clients into a digital-first world.
                  </Paragraph>
                </div>

                <br />

                <Paragraph color="#2d2d2b" size="18px" bold="500" >
                  Mission
                </Paragraph>
                <br />
                <div>
                  <Paragraph color="#2d2d2b" size="14px" bold="500" margin="0 0 0 0.5rem">
                    In CK Group, our mission is to deliver tailor-made solutions that perfectly fit our clients' needs and aim to be the catalyst for their ongoing success in the digital world.
                  </Paragraph>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div className={classes.thePaddingThree}>
                {/* <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <img src={footercall} />
                  </div>
                  <Paragraph color="#ffffff" size="14px" bold="500" margin="0 0 0 0.5rem">
                    +6 012 34567890
                  </Paragraph>
                </div>

                <br /> */}
                <Paragraph color="#2d2d2b" size="18px" bold="500" >
                  Contact Us
                </Paragraph>
                <br />
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <EmailIcon style={{ color: "#2d2d2b" }} />
                  </div>
                  <Paragraph color="#2d2d2b" size="14px" bold="500" margin="0 0 0 0.5rem">
                    cktech6565@outlook.com
                  </Paragraph>
                </div>

                <br />

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <PhoneEnabledIcon style={{ color: "#2d2d2b" }} />
                  </div>
                  <Paragraph color="#2d2d2b" size="14px" bold="500" margin="0 0 0 0.5rem">
                    +6011-61775328
                  </Paragraph>
                </div>

                <br />

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <BusinessIcon style={{ color: "#2d2d2b" }} />
                  </div>
                  <Paragraph color="#2d2d2b" size="14px" bold="500" margin="0 0 0 0.5rem">
                    www.ckgroupmy.com
                  </Paragraph>
                </div>
                {/* <br />

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <img src={footerlocation} />
                  </div>
                  <Paragraph color="#ffffff" size="14px" bold="500" margin="0 0 0 0.5rem">
                    2, Jalan Timur 2/3,
                    Taman Perniagaan
                    81000 Johor Bahru,
                    Johor, Malaysia
                  </Paragraph>
                </div> */}
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div style={{ padding: "0.5rem 0", background: "#fde74c" }}>
        <Paragraph center color="#282828" size="18px" bold="400" >
          copyright © 2023  Privacy Policy
        </Paragraph>
      </div>
    </React.Fragment>
  )

};

export default Footer;

import React, { useState, useEffect } from "react";
import { Drawer, List, ListItem, ListItemText, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Close, Search } from "@mui/icons-material";
import {
  NavbarSidebarList,
  NavbarSidebarListLogo,
  CloseButtonBox,
  CloseIcon,
  SearchContainer,
  Box,
  SearchField,
  SearchButtonMoblie,
  SearchIcon,
} from "./styles";
import Button from "../Button/Button";
import useUser from "../../hooks/useUser";
import { useLocalStorage } from "../../hooks/useLang";

const useStyle = makeStyles((theme) => ({
  sidenavbar: {
    width: 300,
  },
}));

const SidebarMenu = ({ open, onClose, handleDrawerToggle }) => {
  const classes = useStyle();
  const sideDrawerList = (anchor) => (
    <div
      className={classes.sidenavbar}
      role="presentation"
      onClick={() => handleDrawerToggle(anchor, false)}
      onKeyDown={() => handleDrawerToggle(anchor, false)}
    ></div>
  );

  // SEARCH PRODUCT

  const history = useNavigate();
  const { get_user } = useUser();
  const [langJson, setLangJson, removeLangJson] = useLocalStorage(
    "langJson",
    window.localStorage.getItem("language")
  );

  const [token, setToken] = useState(null);

  useEffect(() => {
    let tokenRes = "";
    tokenRes = window.localStorage.getItem("token");
    if (tokenRes) {
      setToken(tokenRes);
    }
    if (window.localStorage.getItem("region") == null) {
      window.localStorage.setItem("region", "MY");
    }
  }, []);

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      {sideDrawerList("left")}
      <List component="nav">
        <NavbarSidebarList>
          <NavbarSidebarListLogo>
            <Link to="/">
              <img src="" />
            </Link>
          </NavbarSidebarListLogo>
          <CloseButtonBox>
            <a onClick={handleDrawerToggle}>
              <CloseIcon />
            </a>
          </CloseButtonBox>

          <br />
          <br />
          <ListItem>
            <Link
              style={{
                textDecoration: "none",
                color: "#000",
                width: "100%",
              }}
              to=""
            >
              <ListItemText
                onClick={() => window.location.replace("#about")}
                primary="About"
                style={{
                  color: "#173d6c",
                  fontWeight: "700 !important",
                }}
              />
            </Link>
          </ListItem>

          <ListItem>
            <Link
              style={{
                textDecoration: "none",
                color: "#000",
                width: "100%",
              }}
              to=""
            >
              <ListItemText
                onClick={() => window.location.replace("#service")}
                primary="Services"
                style={{
                  color: "#173d6c",
                  fontWeight: "700 !important",
                }}
              />
            </Link>
          </ListItem>
          <ListItem>
            <Link
              style={{
                textDecoration: "none",
                color: "#000",
                width: "100%",
              }}
              to=""
            >
              <ListItemText
                onClick={() => window.location.replace("#team")}
                primary="Team"
                style={{
                  color: "#173d6c",
                  fontWeight: "700 !important",
                }}
              />
            </Link>
          </ListItem>
          <ListItem>
            <Link
              style={{
                textDecoration: "none",
                color: "#000",
                width: "100%",
              }}
              to=""
            >
              <ListItemText
                onClick={() => window.location.replace("#blog")}
                primary="Blog"
                style={{
                  color: "#173d6c",
                  fontWeight: "700 !important",
                }}
              />
            </Link>
          </ListItem>
        </NavbarSidebarList>
      </List>
    </Drawer>
  );
};

export default SidebarMenu;

import styled, { keyframes } from "styled-components";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import { FormControl, Input } from "@mui/material";
import { width } from "@mui/system";
import Banner01 from "../../assets/images/Banner01.jpeg";
import Banner02 from "../../assets/images/Banner02.jpg";
import Banner03 from "../../assets/images/Banner03.jpeg";
import Banner04 from "../../assets/images/Banner04.jpeg";
import Banner01Mobile from "../../assets/images/Banner01Mobile.jpeg";

const config = {

    theBannerBG: {
        position: "relative",
        backgroundImage: "url(" + Banner01Mobile + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        height: "900px",

        ...md("md", {
            height: "600px",
            backgroundImage: "url(" + Banner01 + ")",
        }),
    },

    theMarginBottom: {
        marginBottom: "2rem",

        ...md("md", {
            marginBottom: "9rem",
        }),
    },

    theTitleOne: {
        transform: "translateY(5%)",
        padding: "0 1rem",

        ...md("md", {
            transform: "translateY(40%)",
        }),
    },

    theContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },

    theApplyBTN: {
        borderRadius: "0.35rem",
        background: "#d6bf84",
        padding: "0.5rem 0",
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "40%",
        ...md("md", {
            width: "35%",
        }),

    },

    theBorderRight: {
        borderRight: "none",
        ...md("md", {
            borderRight: "1px solid #707070",
            height: "100%"
        }),
    },

    theMaintainanceContent: {
        padding: "4rem 2rem",
        background: "#FFFFFF",
        transform: "translateY(10%)",

        ...md("md", {
            transform: "translateY(60%)",
            border: "1px solid #fde74c",

        }),
    },

    theWordMargin: {
        margin: "unset",

        ...md("md", {
            margin: "90px 0 0 70px"

        }),
    },

    theMaintainanceFlex: {
        display: "flex",
        flexDirection: "row"
    },

    theBannerBGTwo: {
        position: "relative",
        // backgroundImage: "url(" + Banner02 + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        // height: "1100px",

        ...md("sm", {
            // height: "900px",
        }),

        ...md("md", {
            // height: "540px",
        }),
    },

    theBannerTwoPosition: {
        position: "relative",
        transform: "translate(4%,9%)",

        // ...md("md", {
        //     transform: "translateY(50%)"
        // }),
    },

    thePaddingHome: {

        padding: "6rem 0 ",
        ...md("sm", {
            padding: "5rem 0 ",
        }),
        ...md("md", {
            padding: "2rem 0 ",
        }),
    },

    theWordPosition: {
        padding: "9px 14px",
        borderRadius: "30px",
        width: "50%",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
        position: "absolute",
        bottom: "-50px",
        right: "25px",
        background:"#d6bf84",

        ...md("md", {
            width: "65%",
            padding: "14px",

        }),
    },

    thePosition: {
        // position: "absolute",
        // top: "0",
        // transform: "translate(6%,35%)"
    },

    theServiceContent: {
        padding: "0.5rem",
        boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.2)',
        height: "94%",
        borderBottom: "8px solid #fde74c"
    },

    theServiceImg: {
        "& img": {
            width: '100%',
            height: '200px',
        }
    },


    theBannerBGThree: {
        position: "relative",
        backgroundImage: "url(" + Banner03 + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        height: "640px",

        ...md("md", {
            height: "250px",
        }),
    },

    theServiceType: {
        background: "#FFFFFF",
        borderRadius: "0.5rem",
        padding: "0.5rem 0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },

    theApplyBox: {
        padding: "1rem",
        boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.2)',
        borderRadius: "0.5rem",
        height: "auto",

        ...md("md", {
            height: "auto%",
        }),
    },

    theDownloadBtn: {
        borderRadius: "0.25rem",
        padding: "0.75rem 0",
        background: "#EA4843",
        width: "60%",
        cursor: "pointer"
    },

    theBannerBGFour: {
        position: "relative",
        backgroundImage: "url(" + Banner04 + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        height: "500px",

        ...md("md", {
            height: "450px",
        }),
    },

    theApplyBtnBtm: {
        borderRadius: "0.35rem",
        background: "#d6bf84",
        padding: "0.5rem 0",
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "35%",

        ...md("md", {
            width: "20%",

        }),
    },
    theModal: {
        position: "relative",
        // width: "600px",
        padding: "3rem 1rem",
    },
    theCloseModal: {
        position: "absolute",
        top: "47px",
        right: "5%"
    },
    theModalDownloadBtn: {
        borderRadius: "0.25rem",
        padding: "0.75rem 0",
        background: "#EA4843",
        width: "100%",
        cursor: "pointer",
        margin: "1rem auto",

        ...md("md", {
            width: "40%",

        }),
    },
    theStep: {
        display: 'flex',
        justifyContent: "center",
        width: "80%",
        margin: "0 auto 3rem",
        flexDirection: "column",

        ...md("md", {
            flexDirection: "row",

        }),
    },
    TSImageBox: {
        width: "100%",
        display: 'flex',
        justifyContent: "center",
        margin: '0 1rem 0 0',
        position: 'relative',

        ...md("md", {
            width: "50%",
            justifyContent: "flex-end",

        }),
    },
    theArrowAbsolute: {
        position: 'absolute',
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    TSImage: {
        width: '50%',
        padding: "0 0.5rem",
        "& img": {
            width: '100%',
        }
    },
    theTextBox: {
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center",
        margin: "1rem 0 0",

        ...md("md", {
            width: "50%",
            margin: "0",

        }),
    },
    theBorderText: {
        border: '1px solid #EA4843',
        borderRadius: "5px",
        padding: "20px"
    },
    theAboutUs:{
        width:"90%",

        ...md("md", {
            width: "70%",
            margin: "0",

        }),
    }



};

export default makeStyles(config);
import React, { useState, useEffect } from "react";
import {
    AppBar,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    useMediaQuery,
    Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import SidebarMenu from "../SidebarMenu/SidebarMenu";
import {
    MenuIcon,
    NavbarContainer,
    NavbarLogo,
    NavbarMobileLogo,
    NavbarIconMobileList,
    NavbarLower,
    NavbarLowerList,
    NavbarLink,
} from "./styles";
import { md } from "../../styles/responsive";
import loginmobilelogo from "../../assets/images/loginmobilelogo.png";
import Paragraph from "../../components/Typography/Paragraph";
import "./styles.css"
import { i18n } from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import logo from "../../assets/images/logo.png"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const useStyle = makeStyles((theme) => ({

    root: {
        position: "relative",

        "& .slick-track": {
            display: "flex",
            flexDirection: "row",
            alignItems: "inherit",
        },
        "& .slick-slide": {
            height: "inherit",
            "& > div": {
                // height: "100%",
            },
            "& > div > div": {
                // height: "100%",
            },
        },
        "& .slick-arrow": {
            color: "#fff",
            display: "flex!important",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 2,

            "&:before": {
                content: "",
                content: "\f053",
                display: "block",
                width: 24,
                height: 24,
                // backgroundPosition: "center center",
                // backgroundRepeat: "no-repeat",
                // backgroundSize: "contain",
            },

            "&.slick-next": {
                right: 38,

                ...md("sm", {
                    right: "-4px",
                }),

                "&:before": {
                    content: "",
                    content: "\f054",
                    fontFamily: "Font Awesome 5 Free",
                    fontWeight: 900,
                    color: "#000",
                    fontSize: "2rem",
                },
            },

            "&.slick-prev": {
                left: 38,

                ...md("sm", {
                    left: "-8px",
                }),

                "&:before": {
                    content: "",
                    content: "\f053",
                    fontFamily: "Font Awesome 5 Free",
                    fontWeight: 900,
                    color: "#000",
                    fontSize: "2rem",
                },
            },
            "&.slick-disabled": {
                opacity: 1,
            },
        },
    },


    sidenavbar: {
        width: 300,
    },
    theContact: {
        position: "absolute",
        top: "50%",
        left: "10%",
        transform: "translateY(-50%)",
        display: "flex",
    },
    theRow: {
        display: "flex",
    },
    theTopTitle: {
        display: "flex",


        ...md("sm", {
            marginTop: "3rem",
            fontSize: "1rem",
        }),

        ...md("md", {
            marginTop: "4rem",
            marginLeft: "2rem",
            fontSize: "1rem",
        }),

        ...md("xxmd", {
            marginTop: "4rem",
            marginLeft: "2rem",
            fontSize: "1.5rem",
        }),

        ...md("xxl", {
            marginTop: "3rem",
            marginLeft: "2rem",
            fontSize: "2rem",
        }),
    },

    theTopBannerBG: {
        background: "#A78759",
    },

    theTopBanner: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "0.5rem 1.5rem",
    },

    theWishShopping: {
        display: "flex",
        flexDirection: "row",
        width: "10%",
        justifyContent: "space-between",
    },

    theNavLinkActive: {
        background: "#F2F2F2",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "1rem",
    },

    theNavLink: {
        background: "#ffffff",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "1rem",
    },

    theNavCategory: {
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
    },

    theNavFlex: {
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
    },

    theNavImg: {
        marginBottom: "0.5rem",

        "& img": {
            width: "100%"
        }
    },

    theNavItemFlex: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },

    theNavMenu: {
        ...md("md", {
            position: "absolute",
            right: "0",
            top: "99%",
            background: "#FFFFFF",
            width: "100%",
        }),
    },

    theNavMenuRoom: {
        ...md("md", {
            position: "absolute",
            right: "0",
            top: "99%",
            background: "#FFFFFF",
            width: "100%",
        }),
    },

    theNavMenuInspiration: {
        ...md("md", {
            position: "absolute",
            right: "0",
            top: "99%",
            background: "#FFFFFF",
            width: "100%",
        }),
    },

    theNavRoom: {
        padding: "0 0.5rem",

        "& img": {
            width: "100%"
        }
    },

    theNavRoomContent: {
        padding: "1rem 0 1rem 1rem",
    },

    theNavRoomWord: {
        padding: "0 0.5rem",
    },

    theNavBlog: {
        padding: "0.5rem 0",
    },

    theNavBlogImg: {


        "& img": {
            width: "90%"
        }
    },

    theBadge: {
        borderRadius: '50%',
        background: '#000',
        color: '#fff',
        fontSize: '1em',
        lineHeight: 1,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        verticalAlign: 'baseline',
        padding: '0.2rem 0.4rem',
        display: 'initial',
        fontWeight: 600,
        top: '8px',
        marginLeft: '-20px',
        position: "absolute",
        right: '10px',
    },


}));

const Header = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const classes = useStyle();
    const { t, i18n } = useTranslation();

    // const [lang, setLang] = useState();
    // // useEffect(() => {
    // //     var la = window.localStorage.getItem("lang");
    // //     if (la != '' && la != undefined) {
    // //         setLang(la);
    // //     } else {
    // //         setLang("en");
    // //         window.localStorage.setItem("lang", 'en');
    // //     }
    // // }, []);

    const changeLang = (la) => {
        i18n.changeLanguage(la);
        // setLang(la);
        // window.localStorage.setItem("lang", la);

        // window.location.href = window.location.href;
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const [openSideMenu, setOpenSideMenu] = React.useState({ left: false });

    const [openSideCart, setOpenSideCart] = React.useState({ right: false });

    const handleDrawerToggle = (anchor, open) => (e) => {
        if (e.type == "keydown" && (e.key == "Tab" || e.key == "Shift")) {
            return;
        }
        setOpenSideMenu({ [anchor]: open });
        setOpenSideCart({ [anchor]: open });
    };
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [onlineCount, setOnlineCount] = React.useState(1);

    React.useEffect(() => {
        const interval = setInterval(() => {
            const newOnlineCount = Math.floor(Math.random() * 50) + 1; // generate a random number between 1-50
            setOnlineCount(newOnlineCount);
        }, 9000);
        return () => clearInterval(interval); // clear the interval when the component unmounts
    }, []);




    return (
        <>
            {/* <div className="language_bar">
                <Container maxWidth="md">
                    <div className="lang_container">
                        <div className={i18n.language == 'en' ? 'lang active' : 'lang'} onClick={() => {
                            i18n.changeLanguage("en");
                        }}>
                            EN
                        </div>
                        <div className={i18n.language == 'bm' ? 'lang active' : 'lang'} onClick={() => {
                            changeLang("bm");
                        }}>
                            BM
                        </div>
                        <div className={i18n.language == 'ch' ? 'lang active' : 'lang'} onClick={() => {
                            i18n.changeLanguage("ch");
                        }}>
                            CH
                        </div>
                    </div>
                </Container>
            </div> */}
            <div className="language_bar_other">
                <div className="theOnlinePersonBtn">
                    <div>{onlineCount}</div> <div className="theOnlineDot"><FiberManualRecordIcon /></div>Online
                </div>
            </div>
            {isMobile ? (
                null
            ) : (
                <>
                </>
            )}
            <NavbarContainer>

                {/* <Container maxWidth="lg"> */}
                <AppBar position="static">
                    <Toolbar>
                        {isMobile ? (
                            <>
                                {/* <IconButton
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    onClick={handleDrawerToggle("left", true)}
                                >
                                    <MenuIcon color="#000" />
                                </IconButton> */}
                                <NavbarMobileLogo>
                                    <div style={{ padding: "0.5rem 0" }}>
                                        <Link to="/">
                                            <div>
                                                <img src={logo} />
                                            </div>
                                        </Link>
                                    </div>
                                </NavbarMobileLogo>

                                <NavbarIconMobileList>
                                    <div className="theIcon" onClick={() => handleMenu()}>
                                        <span className="theCircle">
                                            {/* <img src={account} /> */}
                                        </span>
                                    </div>
                                </NavbarIconMobileList>

                                <SidebarMenu
                                    anchor="left"
                                    open={openSideMenu.left}
                                    onOpen={handleDrawerToggle("left", true)}
                                    onClose={handleDrawerToggle("left", false)}
                                    handleDrawerToggle={handleDrawerToggle("left", false)}
                                />
                            </>
                        ) : (
                            <>
                                <NavbarLower>
                                    <NavbarLowerList>

                                        <div className={classes.theRow}>
                                            <NavbarLink to="">
                                                <Paragraph size="20px"
                                                    onClick={() => window.location.replace("#about")}
                                                    color={
                                                        location.pathname.replace(/[^A-Za-z]+/g, "") == "#about"
                                                            ? "#EA4843"
                                                            : "#282828"
                                                    }
                                                    bold={
                                                        location.pathname.replace(/[^A-Za-z]+/g, "") == "#about"
                                                            ? "bold"
                                                            : "normal"
                                                    }
                                                    style={{}}
                                                >
                                                    {t("Nav_About")}
                                                </Paragraph>
                                            </NavbarLink>
                                            <NavbarLink to="">
                                                <Paragraph size="20px"
                                                    onClick={() => window.location.replace("#service")}
                                                    color={
                                                        location.pathname.replace(/[^A-Za-z]+/g, "") == "service"
                                                            ? "#EA4843"
                                                            : "#282828"
                                                    }
                                                    bold={
                                                        location.pathname.replace(/[^A-Za-z]+/g, "") == "service"
                                                            ? "bold"
                                                            : "normal"
                                                    }
                                                    style={{}}
                                                >
                                                    Services
                                                </Paragraph>
                                            </NavbarLink>
                                            {/* <NavbarLogo>
                                                <img src={logo} />
                                            </NavbarLogo> */}
                                            {/* <NavbarLink to="">
                                                <Paragraph size="20px"
                                                    onClick={() => window.location.replace("#team")}
                                                    color={
                                                        location.pathname.replace(/[^A-Za-z]+/g, "") == "team"
                                                            ? "#EA4843"
                                                            : "#282828"
                                                    }
                                                    bold={
                                                        location.pathname.replace(/[^A-Za-z]+/g, "") == "team"
                                                            ? "bold"
                                                            : "normal"
                                                    }
                                                    style={{}}
                                                >
                                                    Business 
                                                </Paragraph>
                                            </NavbarLink> */}
                                            <NavbarLink to="">
                                                <Paragraph size="20px"
                                                    onClick={() => window.location.replace("#blog")}
                                                    color={
                                                        location.pathname.replace(/[^A-Za-z]+/g, "") == "blog"
                                                            ? "#EA4843"
                                                            : "#282828"
                                                    }
                                                    bold={
                                                        location.pathname.replace(/[^A-Za-z]+/g, "") == "blog"
                                                            ? "bold"
                                                            : "normal"
                                                    }
                                                    style={{}}
                                                >
                                                    Why Us 
                                                </Paragraph>
                                            </NavbarLink>

                                        </div>

                                    </NavbarLowerList>
                                </NavbarLower>

                            </>
                        )}

                        <>
                            <Menu
                                id="menu-appbar"
                                elevation={0}
                                // getContentAnchorEl={null}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                }}
                                keepMounted
                                // open={open}
                                // onClose={handleClose}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >

                            </Menu>
                        </>
                    </Toolbar>
                </AppBar>
                {/* </Container> */}
            </NavbarContainer>

            {/* <SidebarCart
                anchor="right"
                open={openSideCart.right}
                onOpen={handleDrawerToggle("right", true)}
                onClose={handleDrawerToggle("right", false)}
                handleDrawerToggle={handleDrawerToggle("right", false)}
            /> */}
        </>
    );
};

export default Header;
